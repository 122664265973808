.pages-banner {
  text-align: center;
  height: 18vh;
  color: #ffffff;
  margin: 0;
  width: 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #75bfbd;
  text-transform: uppercase;
}

.banner-line {
  width: 30px;
  border: 3px solid white;
  opacity: 1;
  margin: 0 auto;
  border-radius: 1px;
}