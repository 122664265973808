.contact-row {
  justify-content: space-around;
  margin: 0;
}

.contact-info {
  text-align: left;
}

.contact-description {
  padding: 50px 0 20px;
  text-align: center;
}

.contact-line {
  display: flex;
  margin: 30px 0;
  padding: 0;
}

.contact-line-text {
  margin: 0;
  line-height: 1.2;
  white-space: pre-wrap;
}

.contact-icons {
  height: 20px;
  margin-right: 10px;
}

.ig-icon {
  height: 30px;
}

.contact-row-map {
  border: 0;
  height: 300px;
  width: 600px;
}

.contact-map-container {
  width: 70%;
  position: relative;
  margin: 0 0 50px;
}

.contact-map-container iframe {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 1023px) {
  .contact-map-container {
    width: 100%;
    min-height: 300px;
  }
  .contact-map-container iframe {
    height: 300px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .contact-description {
    text-align: justify;
  }
}