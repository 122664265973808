.menu-bar {
  display: flex;
  align-items: center;
  color: #468ba1;
  font-size: 0.85rem;
}

.prime-logo {
  width: 200px;
}

.nav-links {
  width: 100%;
  text-align: center;
}

.nav-links a {
  margin: 0 6px;
}

.link-active::after {
  content: " ";
  border-bottom: 4px solid #468ba1;
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 0;
}

.phone-icon {
  margin: 5px;
}

.contact-section {
  display: flex;
  align-items: center;
}

.nav-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

@media (max-width: 990px) {
  .link-active::after {
    max-width: 50px;
  }
  .contact-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  .menu-bar {
    justify-content: center;
  }
  .nav-content {
    justify-content: center;
    flex-direction: column;
  }
  .nav-links a {
    margin: 8px 0;
  }
}