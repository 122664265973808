.vaccines-content {
  margin: auto;
  max-width: 1320px;
  padding-bottom: 50px;
}

.vaccine-filters {
  width: 100%;
  justify-content: center;
  padding: 50px 0;
  margin: auto;
}

.vaccine-filter {
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  max-width: 230px;
  min-width: 200px;
  align-items: center;
  width: auto;
  margin: 5px;
}

.vaccine-filter-text {
  margin-left: 8px;
}

.vaccine-filter-title {
  font-size: 1.3rem;
}

.vaccine-filter-subtitle {
  font-size: 1.1rem;
}

.vaccine-filter-title, .vaccine-filter-subtitle {
  margin: 0;
  color: #75BFBD;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
}

.vaccine-filter:hover .vaccine-filter-title, .vaccine-filter:hover .vaccine-filter-subtitle, .vaccine-filter-selected .vaccine-filter-title, .vaccine-filter-selected .vaccine-filter-subtitle {
  color: white;
}

.vaccine-filter:hover, .vaccine-filter-selected {
  background-color: #75BFBD;
  border-radius: 3px;
  width: auto;
}

.accordion-button {
  color: #468BA1;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(189, 224, 229, 0.31);
  color: #468BA1;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../images/arrow-no-collapsed.svg');
}

.accordion-button::after {
  background-image: url('../../images/arow-collapsed.svg');
  height: 0.5rem;
}

.accordion-body {
  text-align: justify;
  color: #696B6B;
  font-size: 0.8rem;
  line-height: 16px;
}