.main-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-banner {
  text-align: left;
  min-height: 50vh;
  color: #ffffff;
  margin: 0;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.banner-container-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-container {
  max-width: 1320px;
}

.banner-container h1 {
  max-width: 500px;
  font-size: 2.5rem;
}

.banner-container .schedule-btn {
  max-width: 130px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .banner-container {
    max-width: 1140px;
    padding: 90px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .banner-container {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .banner-container {
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .banner-container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .banner-container {
    padding: 50px 60px;
  }
}
