.carousel-container {
  background: #bde0e54f;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.quote-icon {
  width: 40px;
  height: 40px;
}

.carousel-container-prev-icon {
  background-image: url("../../images/left-arrow.svg");
}

.carousel-container-next-icon {
  background-image: url("../../images/right-arrow.svg");
}

.quote-row {
  display: flex;
  max-width: 800px;
  margin: auto;
}

.quote-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.quote-text {
  margin-bottom: 5px;
  text-align: justify;
}

.quote-author {
  font-weight: bold;
  color: #696b6b;
  font-style: italic;
}

@media (max-width: 1023px) {
  .quote-row {
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    padding: 40px 20px;
  }

  .quote-author {
    text-align: center;
  }

  .quote-text {
    margin: 15px auto;
  }
}
