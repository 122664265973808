@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-family: "Quicksand", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.menu-bar {
  display: flex;
  align-items: center;
  color: #468ba1;
  font-size: 0.85rem;
}

.prime-logo {
  width: 200px;
}

.nav-links {
  width: 100%;
  text-align: center;
}

.nav-links a {
  margin: 0 6px;
}

.link-active::after {
  content: " ";
  border-bottom: 4px solid #468ba1;
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 0;
}

.phone-icon {
  margin: 5px;
}

.contact-section {
  display: flex;
  align-items: center;
}

.nav-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

@media (max-width: 990px) {
  .link-active::after {
    max-width: 50px;
  }
  .contact-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  .menu-bar {
    justify-content: center;
  }
  .nav-content {
    justify-content: center;
    flex-direction: column;
  }
  .nav-links a {
    margin: 8px 0;
  }
}
.footer-row {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #468ba1;
  height: 50px;
  margin: 0;
}

.copyright-text {
  color: #ffffff;
  margin: 0;
  padding-left: 30px;
  font-size: 0.8rem;
}

.contact-row {
  justify-content: space-around;
  margin: 0;
}

.contact-info {
  text-align: left;
}

.contact-description {
  padding: 50px 0 20px;
  text-align: center;
}

.contact-line {
  display: flex;
  margin: 30px 0;
  padding: 0;
}

.contact-line-text {
  margin: 0;
  line-height: 1.2;
  white-space: pre-wrap;
}

.contact-icons {
  height: 20px;
  margin-right: 10px;
}

.ig-icon {
  height: 30px;
}

.contact-row-map {
  border: 0;
  height: 300px;
  width: 600px;
}

.contact-map-container {
  width: 70%;
  position: relative;
  margin: 0 0 50px;
}

.contact-map-container iframe {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 1023px) {
  .contact-map-container {
    width: 100%;
    min-height: 300px;
  }
  .contact-map-container iframe {
    height: 300px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .contact-description {
    text-align: justify;
  }
}
.carousel-container {
  background: #bde0e54f;
  min-height: 200px;
  display: flex;
  align-items: center;
}

.quote-icon {
  width: 40px;
  height: 40px;
}

.carousel-container-prev-icon {
  background-image: url(/static/media/left-arrow.d94d9c75.svg);
}

.carousel-container-next-icon {
  background-image: url(/static/media/right-arrow.1ec4533e.svg);
}

.quote-row {
  display: flex;
  max-width: 800px;
  margin: auto;
}

.quote-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.quote-text {
  margin-bottom: 5px;
  text-align: justify;
}

.quote-author {
  font-weight: bold;
  color: #696b6b;
  font-style: italic;
}

@media (max-width: 1023px) {
  .quote-row {
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    padding: 40px 20px;
  }

  .quote-author {
    text-align: center;
  }

  .quote-text {
    margin: 15px auto;
  }
}

.gallery-carousel-container {
  width: 100%;
  max-height: 200px;
}

.gallery-carousel-item img {
  max-width: 100%;
  max-height: auto;
  object-fit: cover;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.gallery-carousel-item img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  transition: -webkit-filter 0.5s ease-in-out;
  transition: filter 0.5s ease-in-out;
  transition: filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
}

.row-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 1320px;
}

.row-content-start {
  align-items: flex-start;
}

.row-content-padding {
  padding: 50px 0;
}

.title-container {
  padding: 0;
}

.row-content-reverse {
  flex-direction: row-reverse;
}

.row-content-img {
  height: 320px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  background-origin: initial;
  background-clip: border-box;
  background-clip: initial;
  background-color: transparent;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border-radius: 5px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .row-content {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .row-content {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .row-content {
    max-width: 720px;
  }
  .row-content-img {
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .row-content {
    max-width: 540px;
  }
  .row-content-img {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .row-content-padding {
    padding: 50px 30px;
  }
  .row-content-img {
    height: 200px;
  }
}
.main-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-banner {
  text-align: left;
  min-height: 50vh;
  color: #ffffff;
  margin: 0;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  background-origin: initial;
  background-clip: border-box;
  background-clip: initial;
  background-color: transparent;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.banner-container-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-container {
  max-width: 1320px;
}

.banner-container h1 {
  max-width: 500px;
  font-size: 2.5rem;
}

.banner-container .schedule-btn {
  max-width: 130px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .banner-container {
    max-width: 1140px;
    padding: 90px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .banner-container {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .banner-container {
    max-width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .banner-container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .banner-container {
    padding: 50px 60px;
  }
}

.about-container {
  padding: 0;
  height: 270px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: padding-box;
  background-origin: initial;
  background-clip: border-box;
  background-clip: initial;
  background-color: transparent;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .images-row {
    padding: 0 25px 10px;
  }
}

@media (max-width: 768px) {
  .about-container {
    height: 230px;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .images-row {
    padding: 0 0 60px;
  }
}
.pages-banner {
  text-align: center;
  height: 18vh;
  color: #ffffff;
  margin: 0;
  width: 100%;
}

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #75bfbd;
  text-transform: uppercase;
}

.banner-line {
  width: 30px;
  border: 3px solid white;
  opacity: 1;
  margin: 0 auto;
  border-radius: 1px;
}
.vaccines-content {
  margin: auto;
  max-width: 1320px;
  padding-bottom: 50px;
}

.vaccine-filters {
  width: 100%;
  justify-content: center;
  padding: 50px 0;
  margin: auto;
}

.vaccine-filter {
  display: flex;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
  max-width: 230px;
  min-width: 200px;
  align-items: center;
  width: auto;
  margin: 5px;
}

.vaccine-filter-text {
  margin-left: 8px;
}

.vaccine-filter-title {
  font-size: 1.3rem;
}

.vaccine-filter-subtitle {
  font-size: 1.1rem;
}

.vaccine-filter-title, .vaccine-filter-subtitle {
  margin: 0;
  color: #75BFBD;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
}

.vaccine-filter:hover .vaccine-filter-title, .vaccine-filter:hover .vaccine-filter-subtitle, .vaccine-filter-selected .vaccine-filter-title, .vaccine-filter-selected .vaccine-filter-subtitle {
  color: white;
}

.vaccine-filter:hover, .vaccine-filter-selected {
  background-color: #75BFBD;
  border-radius: 3px;
  width: auto;
}

.accordion-button {
  color: #468BA1;
}

.accordion-button:not(.collapsed) {
  background-color: rgba(189, 224, 229, 0.31);
  color: #468BA1;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(/static/media/arrow-no-collapsed.e97c41ee.svg);
}

.accordion-button::after {
  background-image: url(/static/media/arow-collapsed.0f56eed3.svg);
  height: 0.5rem;
}

.accordion-body {
  text-align: justify;
  color: #696B6B;
  font-size: 0.8rem;
  line-height: 16px;
}
.news-container {
  display: flex;
  margin: 50px auto;
  flex-wrap: wrap
}

.news-card {
  width: 400px;
  margin: 10px;
  min-width: 298px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.news-card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.news-card-image {
  max-width: 100%;
  max-height: auto;
  object-fit: cover;
  height: 130px;
}

.news-card-title {
  text-align: left;
  margin: 0 0 8px;
  font-weight: bold;
  color: #75BFBD;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card-text {
  font-size: 0.9rem;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
  color: #696B6B;
  margin: 0;
}

.news-card-text-no-image {
  -webkit-line-clamp: 10;
}

.news-card-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: #75BFBD;
  margin-top: 10px
}

@media (max-width: 1399px) {
  .news-container {
    justify-content: center;
  }
}
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    margin: 20px auto;
}

.not-found-icon {
    width: 180px;
    margin-bottom: 30px;
}

.not-found-title {
    font-family: Quicksand;
    font-weight: bold;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #468BA1;
}

.not-found-text {
    max-width: 400px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 23px;
    text-align: center;
    color: #696B6B;
    margin: 0 auto 30px;
}
.news-page-container {
  margin: 60px auto;
  max-width: 800px;
}

.news-page-img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-radius: 5px;
  margin: auto;
}

.news-page-title {
  font-weight: bold;
  font-size: 2.1rem;
  color: #468BA1;
  text-align: left;
  margin: 30px auto 0;
}

.news-page-date {
  font-size: 0.8rem;
  text-align: justify;
  color: rgba(41, 41, 41, 0.55);
  margin: 0 auto 20px;
}

.news-page-text {
  font-size: 0.9rem;
  line-height: 18px;
  text-align: justify;
  color: #696B6B;
  white-space: pre-wrap;
}
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

.router-switch {
  min-height: 80vh;
}

.hide {
  display: none;
}

a, a:visited {
  color: #468ba1;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

a:hover {
  color: #366777;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.view-container {
  flex-grow: 1;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.dark-btn, .dark-btn:visited {
  background-color: #75bfbd;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #75bfbd;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 0.85rem;
  text-align: center;
  transition: background 0.3s ease-out;
}

.dark-btn:hover {
  color: #468ba1;
  background-color: white;
  border: 2px solid #75bfbd;
  transition: background 0.3s ease-in;
}

.row-content-title {
  text-align: left;
  margin: 0;
  color: #468ba1;
}

.row-content-text {
  text-align: justify;
  color: #696b6b;
  font-size: 0.9rem;
  white-space: pre-wrap;
}

.row-content-line {
  width: 55px;
  border: 4px solid #75bfbd;
  opacity: 1;
  margin: 0 0 20px 0;
}

.global-spinner {
  margin: auto;
  color: #75bfbd;
}
