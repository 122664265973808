.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    margin: 20px auto;
}

.not-found-icon {
    width: 180px;
    margin-bottom: 30px;
}

.not-found-title {
    font-family: Quicksand;
    font-weight: bold;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: #468BA1;
}

.not-found-text {
    max-width: 400px;
    font-family: Roboto;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 23px;
    text-align: center;
    color: #696B6B;
    margin: 0 auto 30px;
}