.news-page-container {
  margin: 60px auto;
  max-width: 800px;
}

.news-page-img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  max-height: 300px;
  border-radius: 5px;
  margin: auto;
}

.news-page-title {
  font-weight: bold;
  font-size: 2.1rem;
  color: #468BA1;
  text-align: left;
  margin: 30px auto 0;
}

.news-page-date {
  font-size: 0.8rem;
  text-align: justify;
  color: rgba(41, 41, 41, 0.55);
  margin: 0 auto 20px;
}

.news-page-text {
  font-size: 0.9rem;
  line-height: 18px;
  text-align: justify;
  color: #696B6B;
  white-space: pre-wrap;
}