.gallery-carousel-container {
  width: 100%;
  max-height: 200px;
}

.gallery-carousel-item img {
  max-width: 100%;
  max-height: auto;
  object-fit: cover;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.gallery-carousel-item img:hover {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  transition: filter 0.5s ease-in-out;
}
