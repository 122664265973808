.about-container {
  padding: 0;
  height: 270px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .images-row {
    padding: 0 25px 10px;
  }
}

@media (max-width: 768px) {
  .about-container {
    height: 230px;
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .images-row {
    padding: 0 0 60px;
  }
}