.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-link {
  color: #61dafb;
}

.router-switch {
  min-height: 80vh;
}

.hide {
  display: none;
}

a, a:visited {
  color: #468ba1;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
}

a:hover {
  color: #366777;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.view-container {
  flex-grow: 1;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.dark-btn, .dark-btn:visited {
  background-color: #75bfbd;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid #75bfbd;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 0.85rem;
  text-align: center;
  transition: background 0.3s ease-out;
}

.dark-btn:hover {
  color: #468ba1;
  background-color: white;
  border: 2px solid #75bfbd;
  transition: background 0.3s ease-in;
}

.row-content-title {
  text-align: left;
  margin: 0;
  color: #468ba1;
}

.row-content-text {
  text-align: justify;
  color: #696b6b;
  font-size: 0.9rem;
  white-space: pre-wrap;
}

.row-content-line {
  width: 55px;
  border: 4px solid #75bfbd;
  opacity: 1;
  margin: 0 0 20px 0;
}

.global-spinner {
  margin: auto;
  color: #75bfbd;
}