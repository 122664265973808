.row-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  max-width: 1320px;
}

.row-content-start {
  align-items: flex-start;
}

.row-content-padding {
  padding: 50px 0;
}

.title-container {
  padding: 0;
}

.row-content-reverse {
  flex-direction: row-reverse;
}

.row-content-img {
  height: 320px;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  border-radius: 5px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .row-content {
    max-width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .row-content {
    max-width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .row-content {
    max-width: 720px;
  }
  .row-content-img {
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .row-content {
    max-width: 540px;
  }
  .row-content-img {
    height: 200px;
  }
}

@media (max-width: 576px) {
  .row-content-padding {
    padding: 50px 30px;
  }
  .row-content-img {
    height: 200px;
  }
}