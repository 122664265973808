.news-container {
  display: flex;
  margin: 50px auto;
  flex-wrap: wrap
}

.news-card {
  width: 400px;
  margin: 10px;
  min-width: 298px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.news-card-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.news-card-image {
  max-width: 100%;
  max-height: auto;
  object-fit: cover;
  height: 130px;
}

.news-card-title {
  text-align: left;
  margin: 0 0 8px;
  font-weight: bold;
  color: #75BFBD;
  font-size: 1.5rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card-text {
  font-size: 0.9rem;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Roboto', sans-serif;
  line-height: 18px;
  color: #696B6B;
  margin: 0;
}

.news-card-text-no-image {
  -webkit-line-clamp: 10;
}

.news-card-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  color: #75BFBD;
  margin-top: 10px
}

@media (max-width: 1399px) {
  .news-container {
    justify-content: center;
  }
}