.footer-row {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: #468ba1;
  height: 50px;
  margin: 0;
}

.copyright-text {
  color: #ffffff;
  margin: 0;
  padding-left: 30px;
  font-size: 0.8rem;
}
